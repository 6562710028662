@import (reference) "../settings.less";

@media (max-width: 1279px) {
    .cms-subarticles-wrapper {
        columns: 2 !important;
    }

    // SEARCH ENGINE
    #search-engine {
        position: fixed;
        top: 0;
        left: 0;
        background-color: white;
        width: 100vw;
        height: 100dvh;
        overflow-y: auto;
        z-index: 1001;
        padding: 80px 30px;
        -webkit-overflow-scrolling: touch;

        &.show,
        &.show-last-phrases {
        }

        &:not(.show) {

            .no-products-info {
                display: none !important;
            }
        }

        &:not(.show-last-phrases) {

            .suggestion-last-phrases {
                display: none;
            }

            &:has(.suggestion-result-products:empty) {

                .no-products-info {
                    display: block;
                }
            }
        }
    }

    #searchbutton {
        position: absolute;
        top: 81px;
        right: 33px;
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: @header-color;
        font-size: 20px;
        background-color: white;

        &:after {
            content: '\e80d';
            font-family: icomoon;
        }
    }

    .search-engine-close {
        position: absolute;
        height: 60px;
        display: flex;
        align-items: center;
        color: @header-color;
        top: 0;
        left: 0;
        width: 100%;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 15px;
        cursor: pointer;
        user-select: none;
        gap: 20px;
        border-bottom: 1px solid rgba(0,0,0,.2);
        background-color: @light-bg;
        padding: 0 30px;

        &:before {
            content: '\e803';
            font-size: 12px;
            font-family: icomoon;
            font-weight: normal;
        }
    }

    .show-search-engine {

        #search-engine {
            display: block;
        }

        .comparison-drawer {
            z-index: -1;
        }
    }

    #suggestions {
        position: relative;
        top: auto;
        left: auto;
        transform: none;
        width: 100%;
        box-shadow: none;
        border-radius: 0;
        padding: 0;
        margin-top: 16px;
        overflow-y: auto;
        opacity: 1;
        visibility: visible;

        [data-label] {
            margin-bottom: 20px;
        }
    }

    .suggestion-column-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .suggestion-left-column {
        order: 1;
        width: 100%;
    }

    .suggestion-last-phrases {
        width: 100%;
        order: -1;
        margin-bottom: 20px;

        span {
            font-size: 15px;
            padding: 3px 0;
        }
    }

    a.suggestions-advanced-search {
        margin-top: 0;
    }

    .suggestion-result-products {
        margin-bottom: 20px;
    }

    #pills-container {

        &:not(:empty) {
            gap: 10px;
            margin-bottom: 10px;
        }
    }

    .single-pill {
        font-size: 12px;
    }

    .remove-all-filters {
        font-size: 12px;
    }

    // FILTER
    #filter {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        z-index: 1001;
        background-color: white;
        overflow-y: auto;
        padding: 60px 10px 120px;

        .dropdown-picker {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding-top: 20px;
            margin-bottom: -10px;
            border-radius: 4px;

            .dropdown-listing {
                position: static;
                display: none;
                opacity: 1;
                visibility: visible;
                transform: none;
            }

            &.show-dropdown-rwd {

                .dropdown-listing {
                    display: block;
                }
            }

            &:after {
                content: 'Filtry';
                padding: 20px 0 0;
                position: static;
                font-size: 17px;
                color: @header-color;
                font-weight: bold;
                margin-bottom: 10px;
            }
        }

        > h3 {
            display: none;
        }

        .gs-filtr-container {
            border: 0;
            border-bottom: 1px solid @border-color !important;

            &:last-of-type {
                border-bottom: 0 !important;
            }

            &.price-filtering {

                label {
                    line-height: 33px;
                    font-size: var(--fontSize);
                }

                &.show-list {

                    #price-filter-handles,
                    #price-filter-amounts {
                        display: flex;
                    }

                    #price-filter-amounts {
                        padding-bottom: 20px;
                    }
                }

                #price-filter-handles,
                #price-filter-amounts {
                    display: none;
                }
            }

            &.colors {

                ul {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 4px;
                }

                li {
                    display: block;
                }

                label {
                    font-size: 0;
                    padding: 0;
                    width: 34px;
                    height: 34px;
                    border: 2px solid @border-color;
                    outline: 4px solid white;
                    outline-offset: -5px;

                    &:before,
                    &:after {
                        display: none;
                    }

                    &:hover {
                        border-color: @header-color;
                    }
                }

                input:checked + label {
                    border-color: @header-color;
                    outline-width: 3px;
                }
            }

            &.show-list {

                > strong:after {
                    transform: translateY(-50%) scaleY(-1);
                }

                &:not(.price-filtering) > div {
                    display: block;
                }

                > input {
                    display: block;
                }
            }

            > strong {
                margin: 0;
                padding: 13px 0;
                padding-right: 20px;
                display: flex;
                flex-direction: column;
                position: relative;
                color: @header-color;
                font-size: 14px;
                cursor: pointer;
                font-weight: normal;

                &.any-selected {
                    color: @main-color;
                }

                &:after {
                    content: '\e800';
                    font-family: icomoon;
                    font-weight: normal;
                    font-size: 10px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                }

                span {
                    display: block;
                    color: @font-color;
                    font-size: 12px;
                    font-weight: normal;

                    &:empty:before {
                        content: 'brak wybranych';
                    }
                }
            }

            > input {
                font-size: 16px;
                display: none;
                margin-bottom: 15px;
                height: 40px;
            }

            &:not(.price-filtering) > div {
                display: none;
                padding-bottom: 10px;
            }

            &:not(.price-filtering):not(.colors) > div {
                padding-left: 15px;
                margin-top: -5px;
            }

            li {
                display: block !important;
            }

            button {
                display: none;
            }

            input[type=checkbox] {
                display: none;

                &:checked + label:after {
                    transform: scale(1);
                }
            }

            label {
                display: block;
                cursor: pointer;
                user-select: none;
                padding: 4px 0;
                padding-left: 34px;
                position: relative;
                color: @font-color;
                font-size: 14px;
                line-height: 20px;

                &.hide {
                    display: none !important;
                }

                &:hover {
                    text-decoration: underline;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 5px;
                    left: 0;
                    width: 20px;
                    height: 20px;
                    background-color: white;
                    border: 1px solid @border-color;
                    transition: border-radius .1s ease-in-out;
                }

                &:after {
                    content: '\e801';
                    font-family: icomoon;
                    position: absolute;
                    top: 5px;
                    left: 0;
                    width: 20px;
                    height: 20px;
                    color: @header-color;
                    font-size: 14px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: transform .2s ease-in-out;
                    transform: scale(0);
                }
            }
        }
    }

    .close-filter {
        position: absolute;
        height: 60px;
        display: flex;
        align-items: center;
        color: @header-color;
        background-color: @light-bg;
        top: 0;
        left: 0;
        width: 100%;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 15px;
        cursor: pointer;
        user-select: none;
        gap: 20px;
        border-bottom: 1px solid rgba(0,0,0,.2);
        padding: 0 20px;

        &:before {
            content: '\e803';
            font-size: 12px;
            font-family: icomoon;
            font-weight: normal;
        }
    }

    #price-filter-amounts {
        gap: 10px;

        input {
            flex-grow: 1;
        }
    }

    .close-filter-bottom {
        display: flex;
        justify-content: center;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 80px;
        align-items: center;
        background-color: rgba(255,255,255,.5);
        border-top: 1px solid @main-color;

        > span {
            gap: 5px;
            font-size: 13px;

            &[data-count]:after {
                content: attr(data-count);
            }
        }
    }

    .comment-input-wrapper {
        margin-bottom: 12px;
    }

    .show-menu {
        overflow: hidden;

        #tree {
            transform: translate3d(0, 0, 0);
        }

        .tree-canvas {
            opacity: .6;
            pointer-events: auto;
        }
    }

    #tree {
        height: 100vh;
        height: 100dvh;
        z-index: 1002;
        background-color: @light-bg;
        transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
        padding-top: 60px;

        ul:not(.cms-rwd) {
            background-color: white;
        }

        nav {
            position: relative;
            width: min(94vw, 350px);
            height: 100%;
            overflow-x: hidden;
            overflow-y: visible;
            scrollbar-width: none;




            ul:not(.cms-rwd) { // prevents menu from gaining height past 100vh if not yet visible subcategories are taller than 100vh
                display: none;

                &.active {
                    display: block;
                }

                &:has(.active) { // prevents subcategories from gaining height past 100vh if no longer visible overcategories are taller than 100vh

                    > li:not(:has(.active)) {
                        display: none;
                    }
                }
            }

            > ul:first-child { // tree not affected by display none from fix above
                display: block !important;
            }




            > ul {
                position: relative;

                &.cms-rwd {

                    a {
                        font-weight: normal;
                    }
                }

                &:last-child {
                    padding-bottom: 30px;
                }

                &:not(:empty) {

                    &:before {
                        display: block;
                        padding: 10px 20px;
                    }
                }

                &:nth-of-type(2):not(:empty) {
                    border-top: 1px solid rgba(0,0,0,.2);
                    background-color: @light-bg;

                    &:before {
                        content: 'Menu';
                    }

                    a {
                        padding-top: 8px;
                        padding-bottom: 8px;
                    }
                }

                &:before {
                    content: 'Kategorie';
                    display: none;
                }

                &.hide {
                    left: -100%;

                    .close-tree-header {
                        display: none;
                    }

                    & + .cms-rwd {
                        display: none;
                    }
                }

                ul {
                    opacity: 0;
                    pointer-events: none;

                    &.active {
                        left: 100%;
                        top: 0;
                        pointer-events: auto;
                        opacity: 1;
                    }

                    &.hide {
                        left: 0;

                        > .category-header {
                            display: none;
                        }
                    }
                }
            }
        }

        ul {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding-top: 10px;
        }

        .parent > a {
            display: flex !important;
            align-items: center;
            justify-content: space-between;

            &:after {
                content: '\e800';
                font-family: icomoon;
                font-weight: normal;
                margin-left: 20px;
                font-size: 10px;
                transform: rotate(-90deg);
            }
        }

        a:not(.tree-category-all-link) {
            padding: 15px 20px;
            color: @header-color;
            display: block;
            font-weight: bold;
            font-size: 14px;
        }

        a.tree-category-all-link {
            display: block;
            color: @header-color;
            padding: 10px 20px;
            text-decoration: underline;
        }

        .tree-header {
            position: fixed;
            height: 60px;
            display: flex;
            align-items: center;
            color: @header-color;
            top: 0;
            letter-spacing: 0.04em;
            left: 0;
            width: 100%;
            font-weight: bold;
            padding: 0 20px;
            text-transform: uppercase;
            font-size: 15px;
            border-bottom: 1px solid rgba(0,0,0,.2);
            cursor: pointer;
            user-select: none;
            transform: translate3d(0,0,0);

            &:before {
                font-family: icomoon;
                font-weight: normal !important;
                font-size: 15px;
                display: flex;
                align-items: center;
                margin-right: 20px;
            }

            &.close-tree-header:before {
                content: '\e803';
                font-size: 12px;
            }

            &.category-header:before {
                content: '\e800';
                transform: rotate(90deg);
            }
        }

        button,
        small {
            display: none;
        }
    }
}

@media (max-width: 1279px) {
    // OTHER
    .form-modal .goshop-modal {
        width: 90vw;
        max-width: none;
    }

    .availability-modal-inner {

        .img-wrapper {
            display: none;
        }
    }

    .newsletter-cookie-modal {

        .img-wrapper {
            display: none;
        }

        .text-wrapper {
            padding: 30px 10px;
        }
    }
}


@media (max-width: 600px) {
    .tippy-box .tippy-content {
        padding: 10px;
    }

    .infobox {
        padding: 10px;

        > * {
            width: 100%;
        }

        &:before {
            font-size: 80px;
        }
    }

    .goshop-modal-header {
        font-size: 16px;
        padding-right: 50px;
        line-height: 1.2;
        height: 50px;

        &:before {
            width: 40px;
            height: 40px;
        }
    }

    .goshop-modal-close {
        font-size: 14px;
        width: 50px;
        height: 50px;
    }

    .cart-modal .goshop-modal-body {

        .cart-popup-row-wrapper {
            flex-direction: column;
            align-items: center;
        }

        figure {
            height: 80px;
            margin: 0 0 10px;
            width: 100%;
        }

        section {
            text-align: center;

            figure {
                width: 100%;
                height: 80px;
                margin: 0 0 10px;
            }

            > div {
                width: 100%;

                > strong {
                    font-size: 16px;
                }
            }
        }

        .goshop-modal-footer {
            gap: 10px;

            > * {
                width: 100%;
                text-align: center;

                &:nth-child(2) {
                    order: -1;
                }
            }
        }
    }

    .cms-subarticles-wrapper {
        columns: 1 !important;
    }
}
